/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const payrollHero = {
  title: "The smartest small business payroll solution",
  subtext:
    "Save huge and spend 75% less time by using SpotOn + Gusto for a stress-free payroll solution.",
  heroImg: "payroll-hero.png",
  imageClassName: "fixed-height top-adjustement",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/payroll/demo",
  },
  fatTitle: "Payroll",
};

export const payrollTrusted = [
  {
    title: "300m+",
    subtext: "Marketing campaigns",
  },
  {
    title: "5m+",
    subtext: "Loyalty redemptions",
  },
  {
    title: "2,000+",
    subtext: "New businesses each month",
  },
  {
    title: "7+",
    subtext: "Million consumers",
  },
];

export const payrollWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "Service you can trust",
  content:
    "Business is personal, and that’s why SpotOn provides local service and 24/7 support with all of our products and services. For your payroll needs, we’ve partnered with Gusto, the premier payroll service for small-business owners. If you’re using SpotOn Restaurant as your point-of-sale solution, our team will work with you to ensure seamless integration.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/payroll/demo",
  },
};

export const payrollLargeFeatures = {
  sectionTitle: "Combine Gusto with the power of SpotOn",
  featureBlocks: [
    {
      blockTitle: "Easy payroll for any small business",
      blockSubTitle:
        "Save time and money, with quick and easy onboarding for you and your employees.",
      blockList: [
        "Unlimited payrolls",
        "Free W2s",
        "1099 capabilities",
        "Direct deposit",
        "Online employee setup",
        "Automated filings and taxes",
      ],
      blockImg: "payroll-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/payroll/demo",
      },
    },
    {
      blockTitle: "Do more with SpotOn Restaurant",
      blockSubTitle:
        "For restaurants and bars using our lightning fast point-of-sale, you can save even more time with our Gusto integration. Let your employees clock-in and clock-out using your Restaurant POS. Then review employee data, approve hours, and automatically transfer data to your Gusto account directly from your SpotOn dashboard. It’s all integrated to save you time and headaches.",
      blockImg: "payroll-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/payroll/demo",
      },
    },
  ],
};

export const payrollBusinessTypesCar = {
  title: "Payroll made for your business",
  subtext:
    "We bring you payroll that adapts to your needs, no matter what type of small business you run.",
  slides: [
    {
      bg: "restaurant.png",
      cardTitle: "Restaurants",
      cardContent: "Fine Dining, Casual Dining, QSR, Food Stores, Food Trucks",
      cardIcon: "cart.svg",
      icon: "restaurant.png",
      linkTo: "/restaurants",
    },
    {
      bg: "auto.png",
      cardTitle: "Automotive",
      cardContent:
        "Oil Change Shops, Auto Parts, Auto Body, Repair, Tire Shops, Tint Shops",
      icon: "automotive.png",
      linkTo: "/automotive",
    },
    {
      bg: "beauty.png",
      cardTitle: "Health & beauty",
      cardContent:
        "Nail/Hair Salons, Tanning Salons, Barbers, Dentists, Veterinarian",
      icon: "health-and-beauty.png",
      linkTo: "/health-and-beauty",
    },
    {
      bg: "professional.png",
      cardTitle: "Professional Services",
      cardContent:
        "Lawyer, Accountant, Tow Truck, Architect, Plumber, Appraiser",
      icon: "prof-services.png",
      linkTo: "/professional-services",
    },
  ],
};

export const payrollVideo = {
  title: "See how it works",
  videoBtn: "Watch video",
  posterImg: "payroll-poster.png",
  videoUrlId: "wx4JxSMsC4s",
};

export const payrollSolutionsSection = {
  title: "Payroll that plays well with others",
  subText: `Save time and grow revenue with integrated SpotOn technology that makes sense for your business.`,
  SecondaryCta: {
    ctaTitle: "View all",
    ctaTarget: "/products/payroll/demo",
  },
};

export const payrollTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const payrollIntegrations = {
  title: "Integrations",
  subtext:
    "Sync all kind of third party applications and service with SpotOn and automate your revenue growth, retention and marketing processes.",
  integrationsContent: [
    {
      title: "Compatible with SpotOn Register.",
      subtext:
        "Run and grow your business more efficiently than ever before with our cutting-edge point-of-sale solution.",
      contentImg: "register.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "How it Works",
        ctaTarget: "/products/payroll/demo",
      },
    },
    {
      title: "Out of the box Stripe integration",
      subtext:
        "Accept payrolls and move money globally with Stripe’s powerful APIs and software solutions",
      contentImg: "phone.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Read More",
        ctaTarget: "/products/payroll/demo",
      },
    },
  ],
};

export const payrollCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/products/payroll/demo",
  },
};

export const payrollFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/products/payroll/demo",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const payrollSolutionsSlides = [
  {
    title: "Restaurant",
    img: "Restaurant.png",
    link: "/restaurants",
  },
  {
    title: "Payments",
    link: "/products/payments",
    img: "Payments.png",
  },
  {
    title: "Loyalty",
    link: "/products/loyalty",
    img: "Loyalty.png",
  },
  {
    title: "Reserve",
    link: "/products/reserve",
    img: "Reserve.png",
  },
  {
    title: "Website",
    link: "/products/website",
    img: "E-commerce & websites.png",
  },
  {
    title: "Terminal",
    link: "/products/terminal",
    img: "Terminal.png",
  },
];
