import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import {
  payrollHero,
  payrollTrusted,
  payrollWhiteGlove,
  payrollLargeFeatures,
  payrollBusinessTypesCar,
  // payrollVideo,
  payrollSolutionsSection,
  payrollSolutionsSlides,
  payrollTestimonials,
  payrollCTA,
} from "../../../data/product-pages/payroll-data";
import Hero from "../../../components/Hero/hero";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import PartnerLogos from "../../../components/PartnerLogos/PartnerLogos";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import ProductLines from "../../../components/ProductLines/ProductLines";
import ogImage from "../../../images/global_assets/og-image.png";

const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const ProductLines = loadable(() =>
  import("../../../components/ProductLines/ProductLines")
);

const whiteGloveImport = "capital-white-glove.png";

const payroll = () => {
  return (
    <Layout>
      <SEO
        title="SpotOn Payroll | SpotOn + Gusto | Online Payroll Service"
        description="Payroll doesn’t have to be a hassle anymore. With SpotOn payroll service from Gusto, you can spend 75% less time on payroll and save up to $2,000 annually compared to other providers. For restaurants, you can get even more with full POS integration, enabling you to automate the entire payroll process. Ranked by Fit Small Business as the best payroll software for small businesses in 2021, there’s good reason that we’ve partnered with Gusto to help you work faster and smarter."
        image={`https://spoton.com/${ogImage}`}
      />
      <Hero sectionData={payrollHero} circleBg={false} />
      <TrustedNumbers numbersArray={payrollTrusted} />
      <WhiteGlove
        sectionData={payrollWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={payrollLargeFeatures}
        style={{ marginTop: "128px" }}
      />
      <BusinessTypes sectionData={payrollBusinessTypesCar} />
      {/*
      <SuccessStories sectionData={heroSuccess} slides={heroSlides} />
*/}
      {/* <VideoSection sectionData={payrollVideo} /> */}
      <PartnerLogos />
      <ProductLines
        sectionData={payrollSolutionsSection}
        productSlides={payrollSolutionsSlides}
      />
      <TestmonialReviews sectionData={payrollTestimonials} />
      <PartnerLogos />
      <LargeCta sectionData={payrollCTA} />
    </Layout>
  );
};

export default payroll;
